// color variants
@import 'themes-vars.module.scss';
@import 'vars-utils.scss';

// third-party
@import '~react-perfect-scrollbar/dist/css/styles.css';

// ==============================|| LIGHT BOX ||============================== //
.slick-arrow:before {
    color: $grey500;
}

// ==============================|| LIGHT BOX ||============================== //
.fullscreen .react-images__blanket {
    z-index: 1200;
}

// ==============================|| APEXCHART ||============================== //

.apexcharts-legend-series .apexcharts-legend-marker {
    margin-right: 8px;
}

// ==============================|| PERFECT SCROLLBAR ||============================== //

.scrollbar-container {
    .ps__rail-y {
        &:hover > .ps__thumb-y,
        &:focus > .ps__thumb-y,
        &.ps--clicking .ps__thumb-y {
            background-color: $grey500;
            width: 5px;
        }
    }
    .ps__thumb-y {
        background-color: $grey500;
        border-radius: 6px;
        width: 5px;
        right: 0;
    }
}

.scrollbar-container.ps,
.scrollbar-container > .ps {
    &.ps--active-y > .ps__rail-y {
        width: 5px;
        background-color: transparent !important;
        z-index: 999;
        &:hover,
        &.ps--clicking {
            width: 5px;
            background-color: transparent;
        }
    }
    &.ps--scrolling-y > .ps__rail-y,
    &.ps--scrolling-x > .ps__rail-x {
        opacity: 0.4;
        background-color: transparent;
    }
}

// ==============================|| ANIMATION KEYFRAMES ||============================== //

@keyframes wings {
    50% {
        transform: translateY(-40px);
    }
    100% {
        transform: translateY(0px);
    }
}

@keyframes blink {
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes bounce {
    0%,
    20%,
    53%,
    to {
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        transform: translateZ(0);
    }
    40%,
    43% {
        animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
        transform: translate3d(0, -5px, 0);
    }
    70% {
        animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
        transform: translate3d(0, -7px, 0);
    }
    80% {
        transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        transform: translateZ(0);
    }
    90% {
        transform: translate3d(0, -2px, 0);
    }
}

@keyframes slideY {
    0%,
    50%,
    100% {
        transform: translateY(0px);
    }
    25% {
        transform: translateY(-10px);
    }
    75% {
        transform: translateY(10px);
    }
}

@keyframes slideX {
    0%,
    50%,
    100% {
        transform: translateX(0px);
    }
    25% {
        transform: translateX(-10px);
    }
    75% {
        transform: translateX(10px);
    }
}

#firstName-error::first-letter {
    text-transform: capitalize;
}

#lastName-error::first-letter {
    text-transform: capitalize;
}
#authProviderId-error::first-letter {
    text-transform: capitalize;
}
#address-error::first-letter {
    text-transform: capitalize;
}
#address-error::first-letter {
    text-transform: capitalize;
}
#bio-error::first-letter {
    text-transform: capitalize;
}

td {
    word-wrap: break-word;
}

td.appUserTableAddress {
    overflow-wrap: break-word;
    word-break: break-word;
    width: 300px;
}

.relative {
    position: relative;
}

.icon-container {
    position: absolute;
    top: 8px;
    left: 8px;
}

.google-autocomplete svg.googleautocomplete-icon {
    position: absolute;
    margin-left: 13px;
}
.googleautocomplete-icon {
    width: 40px;
    height: 40px;
    right: 0;
}

.otherLocation .addressWrap {
    margin-right: 55px;
}

.react-select__indicators {
}

.addressWrap {
    .react-select__control {
        border-radius: 2px;
        border-color: #bdbdbd;
    }
}

.react-select__value-container .react-select__input-container {
    height: 30px;
}

.client-tab {
    // padding: 4px;
    padding: 10px;
    max-width: 100%;
    > div {
        @media (min-width: 768px) and (max-width: 1199px) {
            flex-wrap: wrap;
            flex-direction: row;
        }

        > div {
            &:first-child {
                // @media (min-width: 1200px) {
                //     min-width: 308px;
                // }
            }

            &:nth-child(2) {
                // @media (min-width: 1200px) {
                //     min-width: 200px;
                // }

                p {
                    font-size: 16px;
                }

                .MuiIconButton-root {
                    padding: 0;
                }
            }
        }
    }

    &-details {
        // padding: 25px;
        padding: 10px 15px;
        @media screen and (min-width: 900px) {
            .info {
                width: 60%;
                justify-content: space-between;
            }
        }
    }

    .MuiInputBase-formControl {
        width: auto;
    }

    .MuiSelect-select {
        .ellipsis {
            max-width: 98px;
            margin-right: 0;
        }
    }

    .roundedTab {
        min-height: 0;
        @media (min-width: 768px) {
            min-width: 285px;
        }
    }
}

.profile-upload-button {
    flex-direction: column;
    align-items: flex-start;
}
